/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/
[data-s-id="diensten-services"] {
    .ac_item_content_title {
        color: $brand-primary !important;
    }
    .ac_item {
        &:hover {        
            .ac_item_content_title {
                color: $brand-primary-darker !important;
            }
        }
    }
}