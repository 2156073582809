/* FIXES
--------------------------------------------------------------------------------------------*/

.v_wider-left-column {

    @include breakpoint(medium-portrait) {
        label {
            width: 60% !important;
        }
        select {
            width: 39% !important;
        }
    }
}


input[type="checkbox"] {
    margin-bottom: 5px !important;
}



//
//     FORM SERVICE ABONNEMENT
//
#gform_5 {
    #input_5_9 {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 100%;
            margin: 0 0 10px 0 !important;
        }
    }
}
 .ac_menu-2_bar_top {
     ul {
         display: flex;
     }
 }
 .ac_hero_slide_content_text {
     display: none;
 }

 .ac_hero_slide {
     height: 500px;
 }

 .ac_hero_alt_slide {
    height:125px;
 }

 [data-s-type="hero-alt"] .ac_hero_alt_container {
     height:125px;
     min-height: 125px;
 }

 [data-s-type="hero-alt"] h1, [data-s-type="hero-alt"] h2, [data-s-type="hero-alt"] h3 {
     padding-top:20px;
 }

 [data-s-type="hero"] {
     height:500px;
 }


 .ac_menu-2_bar_top {
    
    background: #E3E3E3;
    padding-bottom:10px !important;
 }
 .ac_menu-2_logo_img {
    margin-top:-25px;
    max-width: 170px;
}


@media print, screen and (min-width: 50em){
    .ac_menu-2_logo_container {
        position: absolute;
        top: -42px;
        z-index: 900;
    }

    .ac_menu-2_main_container{
        position: relative;
        height: 100px;    
    }

    #menu-top{
        margin-left: 200px;
    }


}

 #menu-top {
     font-size:16px;
 }

 .ac_toptasks_item_image-container {
     background: #E3E3E3;
 }

 .ac_toptasks_item_container {
    background: #E3E3E3;
 }

 .ac_toptasks_item_icon svg {
     color: $brand-primary !important;
     stroke: $brand-primary !important;
     fill:#fff;
 }

 .ac_toptasks_item_content_title {
    color: $brand-primary !important;
 }

 [data-s-type="hero"] .ac_hero_container {
     height:100% !important;
 }

 .slick-dots {
     bottom:40px !important;
 }

 .button:hover {
     color:#fff;
 }



 .js-dropdown-active {
     display: block !important;
 }

 [data-s-id="home-sectie-titel-5"] {
    background:rgb(237, 242, 247);
 }

 [data-s-amount="2"] .slick-slide [data-s-amount-item]{
     max-width: initial !important;
 }

 .ac_footer_primary_column .ac_socials{
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    h2{
        flex: 1 0 100%;
    }
 }

 .ac_toptasks_item_icon{
    padding: 0;
    margin: 0 0 10px 0;
    svg{
        width: 80px;
        height: 80px;
    }
 }

@media screen and (max-width: 49.9375em){
    .ac_menu-2_logo_container{
        top: 0;
        margin-top: -10px;
    }

    .ac_menu-2_logo_img{
        height: 70px;
        margin-top: 0;
    }
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
    z-index:8888;
}

.ac_partners_item {
    width: 100% !important;
    max-width: 100% !important;
}

.ac_hero_slide_content_text {
    display: block;
    h2 {
        font-size: 34px !important;
        font-weight: bold;
        text-shadow: 2px 2px #5a5a5a;
        text-align: center;
    }
}

@media screen and (max-width:900px){
    .ac_menu-2_main_container{ 
        height: auto !important; 
        padding: 0 20px !important;
    }


    .ac_toptasks_item:last-child{
        width: 100%;
        max-width: initial;
    }

    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        width: 120px;
        height: auto;
        margin-top: 28px;
        border: 4px solid white;
        box-shadow: 0 2px 4px rgba(0,0,0,.2);
    }
}

@media screen and (max-width:800px){
    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        margin: -4px 0 -14px 0;
    }
}

@media screen and (min-width: 56.1875em){
    .ac_menu-2_bar_top ul.is-dropdown-submenu{
        display: none;
    }

    .ac_menu-2_bar_top .is-dropdown-submenu-parent:hover{
         ul.is-dropdown-submenu{
            display: flex;
            flex-flow: column nowrap;
        }
    }

}


@media screen and (max-width: 56.1875em){
    [data-m-type="menu-2"]{
        z-index: 999;

        .ac_menu-2_overlay_container{
            z-index: 9;
            top: 70px;
            padding-top: 0;
            height: calc( 100vh - 70px );
        }
    }
}
