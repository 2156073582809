
/* MENU LANDING
--------------------------------------------------------------------------------------------*/

.ac_menu-landing {
    display: flex;
    justify-content: center;
    padding: $default-padding / 2;
    
    position: absolute;
    top: 0;
    width: 100%;
    
    @include breakpoint(medium) {
        padding: $default-padding;
        position: relative;
        top: initial;
    }
}
.ac_menu-landing_logo_container {
 
}
.ac_menu-landing_logo_img {
    width: 60px;
    @include breakpoint(medium) {
        width: 100px;
    }
}