
/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-toptasks-items-max-width: $max-content-800;
$ac-toptasks-items-padding: 0;
$ac-toptasks-items-inner-wrapper-enabled: false;
$ac-toptasks-items-inner-wrapper-padding: 0 $default-padding / 2;
$ac-toptasks-items-inner-wrapper-background-color: $brand-lightest-gray;
$ac-toptasks-items-have-border-dividers: false;

// item
$ac-toptasks-item-padding: $default-padding / 2 $default-padding / 2 $default-padding $default-padding / 2;
$ac-toptasks-item-container-padding: $default-padding / 2 $default-padding $default-padding $default-padding;
$ac-toptasks-item-has-shadow: true;
$ac-toptasks-item-background-color: $brand-background-tertiary;
$ac-toptasks-item-background-hover-color: $brand-background-tertiary-reversed;
$ac-toptasks-item-border-radius: $default-border-radius; 

// image
$ac-toptasks-item-has-image-container: false; 
$ac-toptasks-item-has-image-container-height: 120px;
$ac-toptasks-item-has-image-container-background-color: $default-background-dark;
$ac-toptasks-item-has-image: true;
$ac-toptasks-item-has-image-initial-fade: .1;
$ac-toptasks-item-has-image-hover-fade: .5;

// icon
$ac-toptasks-item-icon-enbled: true;
$ac-toptasks-item-icon-width: 80px; // px of em
$ac-toptasks-item-icon-padding: 24px; // px or em - is deducted from icon width. Say width is 80px and padding is 15px, the actual icon is 50px;
$ac-toptasks-item-icon-margin-bottom: -10px; 
$ac-toptasks-item-icon-bg: inherit; // inherit
$ac-toptasks-item-icon-hover-bg: inherit; // inherit
$ac-toptasks-item-icon-fill: $default-icon-color-light;
$ac-toptasks-item-icon-hover-fill: rgba($default-icon-color-light, .6);

// title
$ac-toptasks-item-title-color: rgba($default-text-color-light, .5);
$ac-toptasks-item-title-color-hover: rgba($default-text-color-light, .9);

// description
$ac-toptasks-item-description-enabled: false;
$ac-toptasks-item-description-color: $default-text-color;
$ac-toptasks-item-description-color-hover: rgba($default-text-color, .9);
$ac-toptasks-item-description-margin-bottom: 1em;

// btn-container
$ac-toptasks-item-btn-container-enabled: false;
$ac-toptasks-item-btn-container-color: $default-background-dark;
$ac-toptasks-item-btn-container-color-hover: rgba($default-background-dark, .9);

@import '__toptasks_globals';

/*--------------------------------------------------------------------------------------------*/


.ac_toptasks_item_container {
    display: block;
    align-items: center;
}
.ac_toptasks_item_icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ac_toptasks_item_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

