
/* SECTION - MENU 2
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/


$ac-menu-2-transparent: false; 
$ac-menu-2-transparent-on-home: false;
$ac-menu-2-z-index: auto;
$ac-menu-2-background: $default-background-light;
$ac-menu-2-gradient-before: false;
$ac-menu-2-gradient-before-color: $default-background-dark;
$ac-menu-2-logo-width: 200px; // height for medium up. 
$ac-menu-2-content-width: 1000px;
$ac-menu-2-divider-height: 30px;

$ac-menu-2-bar-top-enabled: true;
$ac-menu-2-bar-top-justify-content: space-between;
$ac-menu-2-bar-top-background: $brand-white;

$ac-menu-2-main-background: $default-background-light;
$ac-menu-2-main-content-secondary-menu-color: rgba($default-text-color, .5);

$ac-menu-2-bar-bottom-enabled: true;
$ac-menu-2-bar-bottom-justify-content: space-between;
$ac-menu-2-bar-bottom-background: $brand-background-primary-reversed;

//socials icon
$ac-menu-2-socials-icon-background: $default-icon-color-dark;
$ac-menu-2-socials-icon-hover-background: $brand-primary-darker;
$ac-menu-2-socials-icon-color: $default-icon-color-light;
$ac-menu-2-socials-icon-hover-color: $default-icon-color-light;

// mobile
$ac-menu-2-mobile-fixed: true;  
$ac-menu-2-mobile-height: 70px;
$ac-menu-2-mobile-padding: 10px;
$ac-menu-2-mobile-svg-size: 25px;
$ac-menu-2-mobile-svg-color: $default-icon-color-dark;

// overlay
$ac-menu-2-overlay-container-background: $brand-white;
$ac-menu-2-overlay-menu-primary-border: dashed 1px rgba($brand-black, .1);
$ac-menu-2-overlay-menu-primary-text-align: center; // left or center
$ac-menu-2-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-2-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-2-overlay-backdrop-background: rgba($default-background-dark, .8);

@import '__menu_2_globals';


/*--------------------------------------------------------------------------------------------*/

body {
    @include breakpoint(medium-portrait down) {
        padding-top: $ac-menu-2-mobile-height;   
    }
}

[data-m-type="menu-2"] {
    .ac_menu-2_bar_top {
        a {
            font-size: 75%;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $brand-primary;

            &:hover {
                opacity: .8;
            }
        }
        padding-bottom: 0;
    }
    .ac_menu-2_main_content {
        a {
            text-transform: uppercase;
            font-size: 100%;
            letter-spacing: 1px;
            // color: $brand-gray;
            
            &:hover {
                opacity: .8;
            }

            svg {
                fill: $brand-primary;
            }
        }
    }
    .ac_menu-2_bar_bottom {
        a {
            font-size: 100%;
            font-weight: 700;
            text-transform: uppercase;
            color: $brand-white;
            padding: 0 $default-padding / 2;
            &:hover {
                opacity: .8;
            }
        }
        svg {
            fill: rgba($brand-white, .7);
        }
    }
    .ac_menu-2_bar_bottom_container {
        justify-content: center;
    }

    // .ac_menu-2_sticky {
    //     &.is-stuck {
    //         .secondary-menu {
    //             display: block !important;
    //         }
    //     }
    // }

    .ac_menu-2_overlay_menu_primary {
        font-size: 120%; 
        color: $brand-darkest-gray;

    }
    .ac_menu-2_overlay_menu_secondary {
        color: $brand-darkest-gray;

    }


    //
    //     SOCIALS
    //
    
    .ac_socials_text {
        display: none;
    }
    .ac_socials_link {
        flex-direction: row-reverse;
    }

}

